<template>
    <!-- style="width:1080px; margin:65px 0px 0px 225px;" -->
    <!-- <div id="institucional" class="h-100" style="width:1080px; margin:80px 0px 0px 225px;"> -->
    <div id="institucional" class="h-100">
        <Headerbar/>

        <div id="corpo" class="row no-gutters w-100">

            <div id="duvidas">
                <h2 class="d-none d-sm-block">Dúvidas Frequentes</h2>

                <div id="acordion" role="tablist">
                    <b-card no-body class="mb-1" v-for="duvida in this.duvidas" v-bind:key="duvida.id">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle="'accordion-' + duvida.id" variant="info">
                                {{ duvida.titulo }}
                                <b-img-lazy
                                    alt="Clique para abrir/fechar"
                                    fluid
                                    class="d-none d-sm-block"
                                    :src=" require('../../assets/img/seta-accordion.png') "
                                ></b-img-lazy>
                                <b-img-lazy
                                    alt="Clique para abrir/fechar"
                                    fluid
                                    class="d-block d-sm-none"
                                    :src=" require('../../assets/img/seta-accordion-m.png') "
                                ></b-img-lazy>
                            </b-button>
                        </b-card-header>
                        <b-collapse :id="'accordion-' + duvida.id" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text v-html="duvida.texto"></b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>

            <div id="baixar" class="col-12 text-center">
                <p>Baixe nosso app!</p>

                <a href="https://apps.apple.com/br/app/zz-vendas/id1515150526" target="_blank">
                    <b-img-lazy
                        alt="Apple Store"
                        fluid
                        :src=" require('../../assets/img/apple-store.png') "
                    ></b-img-lazy>
                </a>

                <a href="https://play.google.com/store/apps/details?id=br.com.hub2154.zzvendas&hl=pt_BR" target="_blank">
                    <b-img-lazy
                        alt="Play Store"
                        fluid
                        :src=" require('../../assets/img/play-store.png') "
                    ></b-img-lazy>
                </a>
            </div>

        </div>

        <Footerbar/>

    </div>
</template>

<style lang="scss">
#institucional
{
    #corpo
    {
        #duvidas
        {
            width: 100%;
            background-color: #1EBCD5;

            #acordion
            {
                padding: 10px 40px 4% 40px;

                .card
                {
                    margin: 0px !important;
                    padding: 0px;
                    border-radius: 0px;
                    border: 1px solid #ACACAC;
                    border-top: none;

                    .card-header
                    {
                        margin: 0px;
                        padding: 0px !important;
                        background-color: #FAFAFA;
                        border: 0px;
                    }

                    .card-text
                    {
                        font-size: 16px;
                    }

                    .card-body
                    {
                        background-color: #FAFAFA;
                        padding-left: 50px;
                        padding-right: 5%;
                        margin-top: 0px;
                        padding-top: 0px;

                        p
                        {
                            color: #196495;
                            font-family: Montserrat;
                            font-weight: 500;
                        }
                    }

                    .btn
                    {
                        position: relative;
                        width: 100%;
                        margin: 0px;
                        padding: 0px 10% 0px 50px;
                        text-align: left;
                        background: #FAFAFA;
                        // background-image: url('../../assets/img/seta-accordion.png');
                        background-repeat: no-repeat;
                        background-position: right center;
                        border-radius: 0px;
                        height: 85px;
                        border: none;
                        font-weight: 600;
                        font-size: 18px;
                        font-family: Montserrat;
                        letter-spacing: 0px;
                        color: #707070;

                        img
                        {
                            position: absolute;
                            top: 50%;
                            right: 3%;
                            margin-top: -12px;
                        }
                    }

                    .btn:focus
                    {
                        border: 0px;
                        box-shadow: none;
                    }
                }
            }

            h2
            {
                padding: 60px 0px 0px 80px;
                font-weight: Bold;
                font-size: 25px;
                font-family: Montserrat;
                letter-spacing: 0px;
                color: #FFFFFF;
            }
        }
    }
}


// lg
@media (max-width: 992px)
{
    #institucional #corpo #duvidas #acordion .card .btn
    {
        font-size: 16px;
        padding-right: 15%;

        img
        {
            right: 5%;
        }
    }

    #institucional #corpo #duvidas #acordion .card .card-text
    {
        font-size: 14px;
    }
}

// md
@media (max-width: 768px)
{
    #institucional #corpo #duvidas #acordion .card
    {
        .btn
        {
            font-size: 14px;
            padding-right: 15%;
            padding-left: 7%;
        }

        .card-body
        {
            padding-left: 7%;
            padding-right: 5%;
        }
    }
    #institucional #corpo #duvidas #acordion .card .card-text
    {
        font-size: 12px;
    }
}

// sm
@media (max-width: 576px)
{
    #institucional #corpo #duvidas #acordion
    {
        padding-top: 50px;

        .card
        {
            .btn
            {
                height: 80px;
                font-size: 12px;
                padding-right: 12%;
                padding-left: 5%;
                letter-spacing: -0.3px;

                img
                {
                    margin-top: -6px;
                    right: 2%;
                }
            }

            .card-body
            {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    }

    #institucional #corpo #duvidas #acordion .card .card-text
    {
        font-size: 12px;
    }
}
</style>
<script>

import '../../assets/css/institucional.scss';
import Headerbar from "../../components/site_institucional/headerbar";
import Footerbar from "../../components/site_institucional/footerbar";

export default {
    name: 'institucional',
    components: {Headerbar, Footerbar},
    props: [],
    data() {
        return {
            showBlackout: false,
            duvidas: [
                {
                    id: '0',
                    titulo: 'QUEM EFETUA O PAGAMENTO DA COMISSÃO?',
                    texto: 'A loja madrinha da revendedora, cujo é utilizado o estoque para venda.'
                },
                {
                    id: '1',
                    titulo: 'QUANDO ACONTECEM OS PAGAMENTOS?',
                    texto: 'As janelas de comissão são do dia 01-31 (em meses com número menor de dias, considerar o último dia do mês). A loja tem 15 dias para fazer a apuração dos valores e efetuar o pagamento da comissão sobre as vendas. Via RPA.<br/>*O cadastro da revendedora precisa estar completo no sistema.'
                },
                {
                    id: '2',
                    titulo: 'TEM VALOR MÍNIMO DE PAGAMENTO?',
                    texto: 'Sim, o valor mínimo de pagamento é de R$100, se você não chegar a esse valor em algum mês, esse valor fica acumulado até você atingir R$100 de comissão ou 12 meses.'
                },
                {
                    id: '3',
                    titulo: 'QUAIS IMPOSTOS A AREZZO IRÁ RETER?',
                    texto: 'Para todos os revendedores pessoas físicas, a Arezzo precisa deduzir 11%(*) das comissões referente ao INSS, além do IRRF de acordo com a tabela oficial da Receita Federal vigente no período e também do ISS - máximo de 5% (**) de acordo com a regra da Prefeitura do Município onde o contratante está sediado.'
                },
                {
                    id: '4',
                    titulo: 'PRECISO INVESTIR ALGO OU COMPRAR ALGUM KIT?',
                    texto: 'Não. No modelo revendedora Arezzo não há qualquer tipo de investimento da revendedora ou compra de kit, você vende o estoque da loja e é comissionada pelo que vender.'
                },
                {
                    id: '5',
                    titulo: 'E SE EU NÃO VENDER EM ALGUM MÊS?',
                    texto: 'Não há problema, você será de igual modo comissionada sempre que vender, quando não vender não há comissão.'
                },
                {
                    id: '6',
                    titulo: 'COMO FAÇO PARA SER UMA REVENDEDORA AREZZO?',
                    texto: 'Você precisa começar pelo seu cadastro, acesse bit,ly/RevendedoraArezzo e cadastre-se, aguarde a confirmação do seu cadastro e a aceitação da sua loja madrinha e pronto, comece a vender!'
                },
                {
                    id: '7',
                    titulo: 'QUAL O VALOR DA COMISSÃO?',
                    texto: '9% sobre o valor do produto vendido.'
                },
                {
                    id: '8',
                    titulo: 'QUEM FARÁ A ENTREGA DO PRODUTO?',
                    texto: 'A entrega do produto ficará por parte da revendedora, todo o processo de troca ou devolução caso necessário, deverá ser feito direto na loja.'
                },
            ]
        };
    },
    created() {
        document.title = 'Institucional'
    },
}
</script>
